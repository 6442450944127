import {
  type CreateTRPCProxyClient,
  createTRPCProxyClient,
  httpBatchLink,
} from '@trpc/client';
import type { AppRouter } from '@pst/server';
import { oktaAuth } from '@pst/okta';
import { environment } from '@pst/environment';

let client: CreateTRPCProxyClient<AppRouter>;

export type TrpcClient = typeof client;

export const injectTrpcClient = () => {
  return (client ??= createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        // tRPC is only used for the admin API
        url: environment.adminUrl,
        headers() {
          return {
            authorization: 'Bearer ' + oktaAuth.getAccessToken(),
          };
        },
      }),
    ],
  }));
};
