import { Directive, computed, input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[pstButton]',
  host: {
    'class':
      'rounded px-4 py-2 text-sm tracking-widest font-medium outline-none transition-opacity disabled:bg-disabled-surface disabled:text-disabled focus:ring-4 focus:ring-blue-300',
    '[class.bg-primary]': `state() === 'primary'`,
    '[class.active:bg-primary-lightest]': `state() === 'primary'`,
    '[class.hover:bg-primary-lighter]': `state() === 'primary'`,
    '[class.text-white]': `state() === 'primary'`,
    '[class.active:bg-accent-surface]': `state() === 'accent'`,
    '[class.hover:bg-accent-surface]': `state() === 'accent'`,
    '[class.bg-disabled-surface]': `state() === 'disabled'`,
    '[class.text-disabled]': `state() === 'disabled'`,
  },
})
export class ButtonDirective {
  color = input<'primary' | 'accent'>('primary');

  appearDisabled = input(false);

  protected state = computed(() => {
    if (this.appearDisabled()) {
      return 'disabled';
    }
    return this.color();
  });
}
