import { Directive, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'pst-icon',
  standalone: true,
  host: {
    class:
      'material-icons inline-block select-none overflow-hidden bg-no-repeat',
    '[class.h-5]': 'size === "small"',
    '[class.w-5]': 'size === "small"',
    '[class.text-xl]': 'size === "small"',
    '[class.leading-5]': 'size === "small"',
    '[class.h-6]': 'size === "medium"',
    '[class.w-6]': 'size === "medium"',
  },
})
export class IconDirective {
  @Input() size: 'small' | 'medium' = 'medium';
}
