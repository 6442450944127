import { Component, Input } from '@angular/core';

@Component({
  selector: 'pst-spinner',
  standalone: true,
  template: `
    <svg
      [style.height.px]="diameter"
      [style.width.px]="diameter"
      [class.animate-spin]="animate"
      [class.text-primary]="color === 'primary'"
      [class.text-current]="color === 'currentColor'"
      viewBox="0 0 100 100"
    >
      <circle
        r="45%"
        cx="50%"
        cy="50%"
        fill="transparent"
        stroke="currentColor"
        stroke-width="10%"
        stroke-opacity="25%"
      ></circle>
      <circle
        r="45%"
        cx="50%"
        cy="50%"
        fill="transparent"
        stroke="currentColor"
        stroke-width="10%"
        [attr.stroke-dasharray]="animate ? '100% 200%' : '180% 200%'"
        transform="rotate(40, 50, 50)"
      ></circle>
    </svg>
  `,
  host: {
    class: 'block',
  },
})
export class SpinnerComponent {
  @Input() diameter = 100;

  @Input() strokeWidth = 10;

  @Input() color: 'primary' | 'currentColor' = 'primary';

  @Input() animate = true;
}
