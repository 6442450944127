import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { UserService } from '@pst/user';

export const isAdmin: CanActivateFn = (_route, _state) => {
  const router = inject(Router);
  const isAdmin = inject(UserService).isAdmin;
  return toObservable(isAdmin.status).pipe(
    // ignore 'pending' status to wait for the query to succeed or fail
    filter((isAdminStatus) => isAdminStatus !== 'pending'),
    map((_) => {
      if (isAdmin.isSuccess() && isAdmin.data() === true) {
        return true;
      }
      // redirect if the user is not an admin or the query errored
      return router.createUrlTree(['']);
    }),
  );
};
