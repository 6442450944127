import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  computed,
  DestroyRef,
  inject,
  isDevMode,
  signal,
  Signal,
} from '@angular/core';
import {
  QueryState,
  errorResult,
  initialQueryState,
  loadingResult,
  successResult,
} from '@pst/query';
import { EMPTY, Observable, Subject, catchError, switchMap } from 'rxjs';
import { SelectedPropertyService } from '@pst/selected-property';

export interface Context {
  http: HttpClient;
  propertyId: string;
}

export interface Request<TArgs extends unknown[], TData> {
  state: Signal<QueryState<TData, HttpErrorResponse>>;
  error: Signal<HttpErrorResponse | undefined>;
  loading: Signal<boolean>;
  data: Signal<TData | undefined>;
  status: Signal<QueryState<TData, HttpErrorResponse>['status']>;
  send: (...args: [...TArgs]) => void;
}

export function injectRequest<TArgs extends unknown[], TData>(
  factory: (context: Context, ...args: TArgs) => Observable<TData>,
): Request<TArgs, TData> {
  const destroyRef = inject(DestroyRef);
  const propertyIdSignal = inject(SelectedPropertyService).selectedPropertyId;
  const http = inject(HttpClient);
  const context: Context = {
    http,
    propertyId: '',
  };

  const state =
    signal<QueryState<TData, HttpErrorResponse>>(initialQueryState());
  const source$ = new Subject<TArgs>();
  const mutate = (...args: TArgs) => source$.next(args);
  source$
    .pipe(
      switchMap((args) => {
        const propertyId = propertyIdSignal();
        if (!propertyId) {
          return EMPTY;
        }
        context.propertyId = propertyId;
        state.set(loadingResult());
        return factory(context, ...args).pipe(
          catchError((error) => {
            isDevMode() && console.error(error);
            state.set(errorResult(error));
            return EMPTY;
          }),
        );
      }),
      takeUntilDestroyed(destroyRef),
    )
    .subscribe({
      next: (response) => state.set(successResult(response)),
      error: (error) => {
        isDevMode() && console.error(error);
        state.set(errorResult(error));
      },
    });
  const error = computed(() => state().error);
  const loading = computed(() => state().isLoading);
  const data = computed(() => state().data);
  const status = computed(() => state().status);
  return { state, error, loading, data, status, send: mutate };
}
