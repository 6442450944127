import { AppEnvironment } from './environment.interface';

export const environment: AppEnvironment = {
  apiUrl: 'https://dev.pmsselftest.bwhhg.io/',
  adminUrl: 'https://46rcvfb9u9.execute-api.us-west-2.amazonaws.com/stage',
  oktaIssuer:
    'https://hotelsso.dev.bwhhotelgroup.com/oauth2/aus1sknwrutCdxRWx0h8',
  oktaClientId: '0oa1sm439ro46ajg90h8',
  precertificationUrls: {
    getPresignedUrl:
      'https://jvsbmmvpb5.execute-api.us-west-2.amazonaws.com/stage/presign',
    postPrecertification:
      'https://8lakwtg7nj.execute-api.us-west-2.amazonaws.com/stage/precert',
  },
  logViewerUrls: {
    query: 'https://0ttuagxw91.execute-api.us-west-2.amazonaws.com/stage/query',
    status:
      'https://jh38li0cz8.execute-api.us-west-2.amazonaws.com/stage/status',
    logs: 'https://imv5y3okzi.execute-api.us-west-2.amazonaws.com/stage/logs',
  },
};
