import { NgIf } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { Component, computed, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { filter, map } from 'rxjs';
import { SpinnerComponent } from '@pst/spinner';
import { ButtonDirective } from '@pst/button';
import { UserService, VendorPropertyIdsService } from '@pst/user';
import { SelectedPropertyService } from '@pst/selected-property';

import { HeaderComponent } from '../header/header.component';
import { HeaderMenuComponent } from '../header/header-menu.component';
import { ThemeService } from '../theme.service';
import { HeaderNavComponent } from '../header/header-nav.component';

@Component({
  standalone: true,
  imports: [
    ButtonDirective,
    HeaderComponent,
    HeaderMenuComponent,
    HeaderNavComponent,
    NgIf,
    RouterOutlet,
    SpinnerComponent,
  ],
  template: `
    <header>
      <pst-header>
        <pst-header-nav [isAdmin]="user.isAdmin.data() === true" />
        <pst-header-menu
          [userName]="user.name()"
          [selectedPropertyId]="selectedPropertyId()"
          [hasMultipleProperties]="hasMultipleProperties()"
          [currentUrl]="currentUrl()"
          (signOut)="onSignOut()"
        />
      </pst-header>
    </header>
    <div class="mx-auto max-w-screen-xl text-center" role="alert">
      <button
        type="button"
        pstButton
        (click)="reauthenticate()"
        class="w-full !rounded-none"
        *ngIf="!user.isAuthenticated()"
      >
        Your session has expired. Please click here to log in again.
      </button>
    </div>

    <router-outlet></router-outlet>
  `,
  selector: 'pst-layout',
})
export class AppLayoutComponent {
  user = inject(UserService);

  hasMultipleProperties = computed(() => {
    const propertyIds = this.user.propertyIds.data();
    return propertyIds != null && propertyIds.length > 1;
  });

  private selectedProperty = inject(SelectedPropertyService);

  private vendorPropertyIds = inject(VendorPropertyIdsService);

  selectedPropertyId = this.selectedProperty.selectedPropertyId;

  theme = inject(ThemeService);

  oktaAuth = inject(OKTA_AUTH);

  router = inject(Router);

  currentUrl = toSignal(
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((_) => this.router.url),
    ),
    { initialValue: this.router.url },
  );

  onSignOut(): void {
    this.selectedProperty.clearPropertyId();
    this.vendorPropertyIds.clearVendorPropertyIds();
    this.oktaAuth.signOut();
  }

  reauthenticate() {
    this.oktaAuth.signInWithRedirect();
  }
}
