import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tap } from 'rxjs';

interface SelectedPropertyState {
  propertyId: string;
}

export const localStorageKey = 'selectedPropertyId';

@Injectable({ providedIn: 'root' })
export class SelectedPropertyService extends ComponentStore<SelectedPropertyState> {
  selectedPropertyId = this.selectSignal((s) => s.propertyId);

  constructor() {
    super({ propertyId: '' });

    const storedPropertyId = localStorage.getItem(localStorageKey);
    if (storedPropertyId) {
      this.selectPropertyId(storedPropertyId);
    }
  }

  selectPropertyId = this.updater((state, propertyId: string) => {
    localStorage.setItem(localStorageKey, propertyId);
    return { ...state, propertyId };
  });

  clearPropertyId = this.effect(($) => {
    return $.pipe(
      tap(() => {
        this.selectPropertyId('');
      })
    );
  });
}
