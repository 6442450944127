import { Component, computed, inject } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

import { ThemeService } from '../theme.service';

@Component({
  standalone: true,
  imports: [NgOptimizedImage, RouterLink],
  selector: 'pst-header',
  template: `
    <div class="mx-auto flex max-w-screen-xl items-center justify-between">
      <div class="flex items-center">
        <a
          routerLink="/"
          class="flex h-[60px] w-[200px] items-center justify-center outline-none overflow-hidden"
        >
          <img
            [ngSrc]="themedLogoSrc()"
            height="100"
            width="200"
            priority
            alt="BWH Hotels"
          />
        </a>
        <ng-content select="pst-header-nav" />
      </div>
      <div class="flex items-center">
        <ng-content />
      </div>
    </div>
  `,
  host: {
    class: 'block border-b-2 max-w-screen-xl mx-auto p-1',
  },
})
export class HeaderComponent {
  private theme = inject(ThemeService).theme;
  themedLogoSrc = computed(() =>
    this.theme() === 'dark'
      ? 'assets/bwh-hotels-rgb-white.png'
      : 'assets/bwh-hotels-rgb.png',
  );
}
