import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { map, tap } from 'rxjs';
import {
  array,
  Input,
  nullish,
  number,
  object,
  parse,
  string,
  transform,
} from 'valibot';
import { SelectedPropertyService } from '@pst/selected-property';

import { injectRequest } from '../request';
import { getCategoryName } from './get-category-name';

const categoriesResponseSchema = object({
  status: number(),
  message: string(),
  data: array(
    transform(
      object({
        id: number(),
        code: string(),
        description: string(),
        testCount: number(),
        completedTestCount: number(),
        testDefinitionCount: number(),
        helpText: nullish(string()),
      }),
      (c) => {
        return {
          id: c.id,
          code: c.code,
          name: getCategoryName(c.code),
          description: c.description,
          testCount: c.testCount,
          completedTestCount: c.completedTestCount,
          testDefinitionCount: c.testDefinitionCount,
          completed: c.testCount > 0 && c.completedTestCount >= c.testCount,
          helpText: c.helpText,
        };
      },
    ),
  ),
});

export type CategoriesResponse = Input<typeof categoriesResponseSchema>;

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  categoriesDataStale = signal(true);

  categories = injectRequest(({ http, propertyId }) => {
    this.categoriesDataStale.set(false);
    return http
      .get('/getcategories', {
        params: { prop_id: propertyId },
      })
      .pipe(map((response) => parse(categoriesResponseSchema, response).data));
  });

  pingCategoryId = computed(
    () => this.categories.data()?.find((c) => c.code === 'PING')?.id,
  );

  /**
   * Whether a ping test has been completed in this session.
   */
  private pingTestCompleted = signal(false);

  onPingSuccess() {
    this.pingTestCompleted.set(true);
  }

  /**
   * Whether a ping test has been completed.
   * Will be undefined until the category data is loaded.
   */
  hasSuccessfulPingTest = computed(() => {
    if (this.pingTestCompleted()) {
      return true;
    }
    const status = this.categories.state().status;
    if (status === 'success') {
      // TODO: Angular Query has type narrowing support that would avoid the need for this non-null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.categories
        .data()!
        .some((c) => c.code === 'PING' && c.completedTestCount > 0);
    }
    if (status === 'idle' || status === 'loading') {
      return undefined;
    }
    return false;
  });

  constructor() {
    const selectedPropertyId = inject(
      SelectedPropertyService,
    ).selectedPropertyId;
    effect(
      () => {
        // because this service is providedIn: 'root',
        // it's not implicitly reset when the property id changes
        // like component-level services are (due to routing),
        // so we reset it manually when the property id is changed.
        selectedPropertyId();
        this.pingTestCompleted.set(false);
        this.categories.send();
      },
      { allowSignalWrites: true },
    );
  }

  resettingCategoryId = signal(0);

  resetCategory = injectRequest(({ http, propertyId }, categoryId: number) => {
    this.resettingCategoryId.set(categoryId);
    return http
      .post('/resettests', null, {
        params: {
          prop_id: propertyId,
          cat_id: categoryId,
        },
      })
      .pipe(tap(() => this.categories.send()));
  });

  invalidateCategoriesData() {
    this.categoriesDataStale.set(true);
  }
}
